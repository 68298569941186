import React, {useEffect, useState} from "react";
import "../css/WelcomeScreen.css"
import {FireBasePopup} from "../auth/FireBase";
import Logo from "../images/logo.png";

export const WelcomeScreen = ({isAuthenticated}) => {
    const [showAuth, setShowAuth] = useState(false);

    useEffect(() => {
        if (isAuthenticated) {
            window.location.href = "/dashboard";
        }
    }, [isAuthenticated]);


    const onCreateNewGame = async () => {setShowAuth(true)}

    return (
        <>
            {showAuth && <FireBasePopup setShowAuth={setShowAuth} successUrl={'/dashboard'}/>}
            <div className="page-container-v2">
                <div className="page-container-darkener">
                    <img className="dashboard-logo" src={Logo}/>
                    <div className="title-container">
                        <p>The classic Text Based Adventure</p>
                        <p>Brought to the Modern Age</p>
                    </div>
                    <button className="cta" onClick={() => onCreateNewGame()}>Play for Free</button>
                    <div className="legal-info-p-copyright-welcome-screen">
                        <div className="legal-info-terms-container-welcome">
                            <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=e385d701-3f62-40f9-b661-b485d5ca188a"
                               className="legal-info-item">Terms of Use</a>
                            <p className="legal-info-item-dot">•</p>
                            <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=50078d71-a78f-496b-a633-712d0df91b8b"
                               className="legal-info-item">Privacy Policy</a>
                        </div>
                        <p>© 2024 AI Brain Games. All Rights Reserved.</p>
                    </div>

                </div>
            </div>
        </>
    );
};